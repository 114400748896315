<template>
  <div class="container" id="container">
    <p class="title" id="title">订单详情</p>
    <div calss="search" id="search">
      <el-form
        :model="queryParams"
        ref="queryForm"
        size="small"
        label-width="120px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="产品名称" prop="pname">
              <el-input
                v-model="queryParams.pname"
                placeholder="请输入"
                clearable
                @keyup.enter.native="handleQuery"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预计成交日期" prop="dealDate">
              <el-date-picker
                clearable
                v-model="dealDateRange"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="-"
                start-placeholder="开始日期"
                :default-time="['00:00:00', '23:59:59']"
                end-placeholder="结束日期"
                placeholder="请选择预计成交日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="发货状态" prop="orderNo">
              <el-select v-model="queryParams.type">
                <!-- <el-option :value="0" label="未支付"></el-option>
                
                <el-option :value="3" label="待评价"></el-option>
                <el-option :value="4" label="已完成"></el-option>
                <el-option :value="-3" label="售后/退款"></el-option> -->
                <el-option :value="1" label="待发货"></el-option>
                <el-option :value="2" label="待收货"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="order" v-loading="loading">
      <el-table
        border
        :data="tableData"
        style="width: 100%;"
        :height="tableHeight"
        :cell-style="cellStyle"
        :header-cell-style="cellStyle"
      >
        <el-table-column prop="orderId" show-overflow-tooltip label="订单编号">
        </el-table-column>
        <el-table-column label="产品名称" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.orderInfoList[0].storeName }}
          </template>
        </el-table-column>
        <el-table-column label="产品类型" show-overflow-tooltip
          ><template slot-scope="scope">
            {{ scope.row.orderInfoList[0].storeType }}
          </template>
        </el-table-column>
        <el-table-column label="支付金额"
          ><template slot-scope="scope">
            {{ scope.row.orderInfoList[0].price }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="200px">
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.page"
        :page-sizes="[20, 30, 40, 50]"
        :page-size="20"
        layout=" sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      queryParams: { type: 1, page: 1, limit: 20 },
      dealDateRange: [],
      tableData: [],
      cellStyle: { textAlign: "center" },
      total: 0,
      tableHeight: "",
    };
  },
  mounted() {
    if (sessionStorage.getItem("token")) {
      this.getList();
      this.tableHeight =
        document.getElementById("container").offsetHeight -
        document.getElementById("title").offsetHeight -
        document.getElementById("search").offsetHeight +
        200 -
        120 +
        "px";
    } else {
      this.$router.push({ path: "/login" });
    }
  },
  methods: {
    getList() {
      this.queryParams.startTime = this.dealDateRange[0] || null;
      this.queryParams.endTime = this.dealDateRange[1] || null;
      this.loading = true;
      this.$axios
        .get("/api/front/order/list", { params: this.queryParams })
        .then((res) => {
          console.log(res, "fdsfsdfdsf");
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
          this.loading = false;
        });
    },
    handleQuery() {
      this.getList();
    },
    resetQuery() {
      this.queryParams = { type: 1, page: 1, limit: 20 };
      this.dealDateRange = [];
      this.handleQuery();
    },
    handleSizeChange(val) {
      this.queryParams.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.queryParams.page = val;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  .order {
    background-color: #fff;
    padding: 30px;
    .el-pagination {
      margin-top: 30px;
      text-align: right;
    }
  }
  .title {
    margin: 17px 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
  }
  .search {
    margin: 38px 0 20px 0;
  }
}
</style>
